<template>
	<div class="container login">

		<!-- Logo and title -->
		<div class="logo-large-container">
			<img src="https://wefix-files.s3.eu-west-2.amazonaws.com/images/logos/wefix/wefix-colour.svg" alt="WeFix" class="logo img-4x" />
		</div>
		<h1>Login</h1>

		<!-- Email input -->
		<div class="form-group">
			<input type="email" class="form-control form-control-lg" v-model="email" placeholder="Email">
		</div>

		<!-- Password input -->
		<div class="form-group">
			<input type="password" class="form-control form-control-lg" v-model="password" v-on:keyup.enter="login()" placeholder="Password">
		</div>

		<!-- Login button -->
		<div class="row">
			<div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
				<button type="submit" class="btn continue-btn btn-block btn-lg btn-rounded" @click="login()" :disabled="loading">
					<span v-if="!loading">Log in</span>
					<span v-if="loading"><animated-loading size="sm" class="mr-2"></animated-loading> Logging in</span>
				</button>
			</div>
		</div>

		<div class="disclaimer-text">
			<p>
				*Only authorised users can access this system and legal action may be taken against unauthorised users, under the computer misuse act of 1990.
			</p>
		</div>

	</div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
	name: "LoginIndex",

	data: function() {
		return {
			email: null,
			loading: false,
			password: null
		}
	},

	mounted() {
		this.clearCarrier();
		this.clearCarrierDetails();
	},

	methods: {
		...mapActions([
			'clearCarrier',
			'clearCarrierDetails',
			'displayToast',
			'fetchUser'
		]),

		login() {
			this.loading = true;

			if (!this.email || !this.password) {
				this.loading = false;
				this.displayToast({text: 'Enter your login credentials.', type: 'error'});
				return;
			}

			this.fetchUser({email: this.email, password: this.password})
				.then(() => {
					window.location.href = '/';
				})
				.catch(errors => {
					this.loading = false;
					this.displayToast({text: errors, type: 'error'});
				})
		}
	}
}
</script>
<style lang="scss">
	@import '../../styles/core/whitelabel/default.scss';
</style>